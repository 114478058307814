import { extractUtmParams, getRedirectUrl, setUtmParams } from "./util";

const App = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const utmParams = extractUtmParams(queryParams);
  const redirectedUrl = getRedirectUrl(queryParams);

  setUtmParams(redirectedUrl, utmParams);
  window.location.href = redirectedUrl.href;
  return <></>;
};

export default App;
