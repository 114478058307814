import { UtmParam } from "./types";

export const getRedirectUrl = (queryParams: URLSearchParams): URL => {
  const redirectUrl = queryParams.get("redirect_url");

  if (redirectUrl) {
    return new URL(redirectUrl);
  }

  const country = queryParams.get("country");

  switch (country) {
    case "sg":
      return new URL("https://www.jobstreet.com.sg/career-resources/seekmax");
    case "ph":
      return new URL("https://www.jobstreet.com.ph/career-resources/seekmax");
    case "my":
      return new URL("https://www.jobstreet.com.my/career-resources/seekmax");
    case "id":
      return new URL("https://www.jobstreet.co.id/career-resources/seekmax");
    default:
      return new URL("https://www.jobstreet.co.id/career-resources/seekmax");
  }
};

export const extractUtmParams = (queryParams: URLSearchParams): UtmParam => {
  return {
    utm_source: queryParams.get("utm_source") ?? "",
    utm_medium: queryParams.get("utm_medium") ?? "",
    utm_campaign: queryParams.get("utm_campaign") ?? "",
  };
};

export const setUtmParams = (url: URL, params: UtmParam): void => {
  if (params.utm_source !== "") url.searchParams.set("utm_source", params.utm_source);
  if (params.utm_medium !== "") url.searchParams.set("utm_medium", params.utm_medium);
  if (params.utm_campaign !== "") url.searchParams.set("utm_campaign", params.utm_campaign);
};
